import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { SkillAPI } from "../../services/skills.services";
import { IState } from "../../types/NetworkState";

export interface ISkill {
  id: number;
  parent_id: number;
  s_name: string;
  uid: string;
}

const initialState: IState<ISkill> = {
  duration: 0,
  entities: [],
  loading: "idle",
};

export const fetchSkills = createAsyncThunk("skills/fetch", async () => {
  const entities = await SkillAPI.fetch();
  return {
    entities,
  };
});

const skillsSlice = createSlice({
  name: "skills",
  initialState,
  reducers: {
    setSkills(state, action: PayloadAction<ISkill[]>) {
      return { ...state, entities: action.payload };
    },
    setDuration(state, action: PayloadAction<number>) {
      return { ...state, duration: action.payload };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchSkills.fulfilled, (state, action) => {
        return { ...state, ...action.payload, loading: "done" };
      })
      .addCase(fetchSkills.pending, (state) => {
        return { ...state, loading: "pending" };
      });
  },
});

const { actions, reducer } = skillsSlice;

export const { setSkills } = actions;

export default reducer;
