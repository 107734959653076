import React, { useMemo } from "react";
import Spin from "antd/lib/spin";

import MapLegend from "./MapLegend";
import MapLegendGradient from "./MapLegendGradient";
import Disclaimer from "./Disclaimer";
import { MapFooterColumn, MapFooterContainer, MapFooterSourceInformation } from "../Map/Map.styled";
import { useSelector } from "react-redux";
import { selectStatisticsByMode } from "../../store/statistics/statistics.selectors";

type MapFooterProps = {
  region?: number;
};

const MapFooter: React.FC<MapFooterProps> = ({ region }) => {
  const statistics = useSelector(selectStatisticsByMode(!!region));

  const vacanciesCount = useMemo(() => {
    return statistics.reduce(
      (acc, item) => acc + item.result_demand + item.result_remote_demand,
      0
    );
  }, [statistics]);

  if (vacanciesCount === 0) return null;

  return (
    <MapFooterContainer>
      <MapFooterColumn>
        <MapFooterSourceInformation>
          Источники данных: <br /> <b>HeadHunter, SuperJob, Работа в России</b>
        </MapFooterSourceInformation>
      </MapFooterColumn>
      <MapFooterColumn>
        <React.Suspense fallback={<Spin />}>
          <Disclaimer />
        </React.Suspense>
      </MapFooterColumn>
      <MapFooterColumn>
        <MapLegendGradient />
        <MapLegend vacanciesCount={vacanciesCount} />
      </MapFooterColumn>
    </MapFooterContainer>
  );
};

export default React.memo(MapFooter);
