import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Switch, Route, BrowserRouter as Router } from "react-router-dom";
import { YMaps } from "react-yandex-maps";
import Spin from "antd/lib/spin";
import Layout from "antd/lib/layout";

import { fetchAllData } from "./store/app/app.reducer";
import { selectLoading } from "./store/app/app.selectors";

import Header from "./components/Header/Header";
import Sidebar from "./components/Sidebar/Sidebar";
import SplitScreen from "./components/SplitScreen";
import Map from "./components/Map/Map";

import NotFound from "./pages/NotFound";

function App() {
  const dispatch = useDispatch();
  const { loading, label } = useSelector(selectLoading);

  useEffect(() => {
    dispatch(fetchAllData());
  }, [dispatch]);

  return (
    <Spin tip={label} spinning={loading}>
      <Layout>
        <Router>
          <YMaps version="2.1.77" preload>
            <Header />
            <Switch>
              <Route
                exact
                path={["/", "/:region"]}
                render={(props) => (
                  <SplitScreen>
                    <Map {...props} />
                    <Sidebar {...props} />
                  </SplitScreen>
                )}
              />
              <Route component={NotFound} />
            </Switch>
          </YMaps>
        </Router>
      </Layout>
    </Spin>
  );
}

export default React.memo(App);
