import React from "react";
import { MapLegendContainer, MapLegendMarker, MapLegendTip } from "../Map/Map.styled";
import markerImage from "../../assets/marker.png";

type MapLegendProps = {
  vacanciesCount: number;
};

const MapLegend: React.FC<MapLegendProps> = ({ vacanciesCount }) => {
  return (
    <MapLegendContainer>
      <MapLegendMarker>
        <img src={markerImage} width={16} alt="" />
        <span>{vacanciesCount}</span>
      </MapLegendMarker>
      <MapLegendTip>Количество открытых вакансий</MapLegendTip>
    </MapLegendContainer>
  );
};

export default React.memo(MapLegend);
