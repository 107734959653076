import React, { useState } from "react";
import Button from "antd/lib/button";
import Modal from "antd/lib/modal/Modal";

const EducationTable = React.lazy(() => import("./EducationTable"));

const Education = () => {
  const [isVisible, setIsVisible] = useState(false);

  return (
    <>
      <Button type="default" onClick={() => setIsVisible(true)}>
        Обучаться
      </Button>
      <Modal visible={isVisible} onCancel={() => setIsVisible(false)} footer={null} width={1200}>
        <EducationTable />
      </Modal>
    </>
  );
};

export default Education;
