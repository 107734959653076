import { configureStore } from "@reduxjs/toolkit";
import appReducer from "./app/app.reducer";
import fieldsReducer from "./fields/fields.reducer";
import positionsReducer from "./positions/positions.reducer";
import regionsReducer from "./regions/regions.reducer";
import skillsReducer from "./skills/skills.reducer";
import statisticsReducer from "./statistics/statistics.reducer";
import vacanciesReducer from "./vacancies/vacancies.reducer";

export const middleware = (getDefaultMiddleware: any) => {
  return getDefaultMiddleware({ immutableCheck: true, thunk: true });
};

export const reducer = {
  app: appReducer,
  regions: regionsReducer,
  positions: positionsReducer,
  skills: skillsReducer,
  fields: fieldsReducer,
  statistics: statisticsReducer,
  vacancies: vacanciesReducer,
};

export const store = configureStore({
  reducer,
  middleware,
  devTools: process.env.NODE_ENV === "development",
});

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;
