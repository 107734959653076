import { FilterFilled, FilterOutlined } from "@ant-design/icons/lib";
import Button from "antd/lib/button";
import React, { useCallback, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import { StatisticDataType } from "../../store/statistics/statistics.reducer";
import { selectStatisticsByMode } from "../../store/statistics/statistics.selectors";

import {
  RatingContainer,
  RatingFilter,
  RatingFilterItem,
  RatingList,
  RatingNone,
  RatingTitle,
} from "./Rating.styled";

import RatingItem from "./RatingItem";
import LegendChart from "./LegendChart";

const isRedesign = process.env.REACT_APP_THEME === "redesign";

type RegionChartProps = {
  searchParams: URLSearchParams;
  setFilter: (key: string, value?: string | number) => void;
  region?: StatisticDataType;
};

const RegionChart: React.FC<RegionChartProps> = ({ region, searchParams, setFilter }) => {
  const history = useHistory();
  const statistics = useSelector(selectStatisticsByMode(!!region));

  const [mode, setMode] = useState<"demand" | "avg_salary">("demand");
  const [limit, setLimit] = useState(10);
  const [isShownFilters, setIsShownFilters] = useState(false);

  const toggleFilters = () => setIsShownFilters(!isShownFilters);

  const rating = useMemo(
    () =>
      statistics
        .filter((item) => item.result_demand > 0 || item.result_remote_demand > 0)
        .sort((a, b) => {
          if (mode === "demand") {
            return a.result_demand > b.result_demand ? -1 : 1;
          } else {
            return a.avg_salary > b.avg_salary ? -1 : 1;
          }
        })
        .slice(0, limit),
    [mode, limit, statistics]
  );

  // Получаем максимальное значение и берем его за 100% длинны графика
  const maxValueSalary = Math.max(...rating.map((item) => item.avg_salary));
  const maxValueDemand = rating[0] ? rating[0].result_demand + rating[0].result_remote_demand : 0;

  const handleLoadMore = useCallback(() => {
    const pagesCount = Math.floor(rating.length / 10);
    const currPage = Math.floor(limit / 10);
    if (pagesCount <= currPage) {
      setLimit(currPage * 10 + 10);
    }
  }, [limit, rating.length]);

  const handleSelect = useCallback(
    (res_code: string | number) => {
      const params = searchParams.toString();
      if (region) {
        setFilter("city", res_code);
      } else {
        history.push(`/${res_code}?${params}`);
      }
    },
    [history, region, searchParams, setFilter]
  );

  const Filter = () =>
    isRedesign ? (
      <FilterFilled onClick={toggleFilters} />
    ) : (
      <FilterOutlined onClick={toggleFilters} />
    );

  const handleSetDemandFilter = () => setMode("demand");
  const handleSetSalaryFilter = () => setMode("avg_salary");

  return (
    <RatingContainer>
      <RatingTitle>
        <h3>
          Востребованность кадров в субъектах РФ <Filter />
        </h3>
      </RatingTitle>
      {isShownFilters && (
        <RatingFilter>
          <p>
            Сортировать по:
            <RatingFilterItem isActive={mode === "demand"} onClick={handleSetDemandFilter}>
              частоте упоминания
            </RatingFilterItem>
            <RatingFilterItem isActive={mode === "avg_salary"} onClick={handleSetSalaryFilter}>
              средней зарплате
            </RatingFilterItem>
          </p>
        </RatingFilter>
      )}
      <RatingList>
        {rating.length > 0 ? (
          rating.map((item) => (
            <RatingItem
              key={item.res_id}
              bar={item}
              maxDemand={maxValueDemand}
              maxSalary={maxValueSalary}
              onSelect={handleSelect}
              rowId={region ? "res_name" : "res_code"}
            />
          ))
        ) : (
          <RatingNone>Нет данных</RatingNone>
        )}
      </RatingList>
      {statistics.length >= 10 && (
        <Button type="link" block onClick={handleLoadMore}>
          Показать больше
        </Button>
      )}
      {rating.length > 0 && <LegendChart />}
    </RatingContainer>
  );
};

export default React.memo(RegionChart, (a, b) => a.region === b.region);
