import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RegionAPI } from "../../services/regions.services";
import { IState } from "../../types/NetworkState";

export interface IRegion {
  avg_salary: number;
  res_code: string;
  res_id: number;
  res_name: string;
  result_demand: number;
  result_offer: number;
  result_ratio: number;
  result_remote_demand: number;
  result_remote_offer: number;
}

const initialState: IState<IRegion> = {
  entities: [],
  loading: "idle",
};

export const fetchRegions = createAsyncThunk("regions/fetch", async () => {
  const entities = await RegionAPI.fetch();
  return {
    entities,
  };
});

const regionsSlice = createSlice({
  name: "regions",
  initialState,
  reducers: {
    setRegions(state, action: PayloadAction<IRegion[]>) {
      return { ...state, entities: action.payload };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchRegions.fulfilled, (state, action) => {
        return { ...state, ...action.payload, loading: "done" };
      })
      .addCase(fetchRegions.pending, (state) => {
        return { ...state, loading: "pending" };
      });
  },
});

const { actions, reducer } = regionsSlice;

export const { setRegions } = actions;

export default reducer;
