import axios from "axios";
import { IField } from "../store/fields/fields.reducer";

const fetch = async () => {
  try {
    const response = await axios.get<IField[]>("ref/subjareas");
    return Array.isArray(response.data) ? response.data : [];
  } catch (error) {
    throw new Error("Ошибка при загрузке списка сфер деятельности");
  }
};

export const FieldAPI = { fetch };
