import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "..";

export const selectFields = (state: RootState) => state.fields;

export const selectFieldsEntities = createSelector(selectFields, (state) => state.entities);

export const selectFieldsOptions = createSelector(selectFields, (state) =>
  state.entities
    .map((item) => ({ value: item.id, label: item.s_name }))
    .sort((a, b) => a.label.localeCompare(b.label, "ru"))
);

export const selectFieldById = (id?: number) =>
  createSelector(selectFields, (state) => state.entities.find((item) => item.id === id));
