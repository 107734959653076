import { useCallback, useMemo } from "react";

import { useHistory, useLocation } from "react-router-dom";

export type FilterParams = { [key: string]: string | number };

// Оптимизировать в дальнейшем
export const useFilters = () => {
  const location = useLocation();
  const history = useHistory();

  const state = useMemo(() => {
    const search = new URLSearchParams(location.search);
    // Парсим параметры
    // Создаем карту и заполняем соответствующими типами данных
    const filter: FilterParams = {};
    search.forEach((value, key) => {
      // Проверяем чтобы в фильтре не было лишних полей
      //const isInFilter = Object.values(filterKey).some((item) => item === value);
      filter[key] = !isNaN(+value) ? +value : value;
    });
    return {
      search,
      filter,
    };
  }, [location.search]);

  const setState = useCallback(
    (key: string, value?: string | number) => {
      if (value) {
        state.search.set(key, value.toString());
      } else {
        state.search.delete(key);
      }
      history.push(location.pathname + "?" + state.search.toString());
    },
    [history, location.pathname, state.search]
  );

  return [state, setState] as const;
};
