//https://ru.wikipedia.org/wiki/%D0%A1%D0%BF%D0%B8%D1%81%D0%BE%D0%BA_%D0%B3%D0%BE%D1%80%D0%BE%D0%B4%D0%BE%D0%B2_%D0%A0%D0%BE%D1%81%D1%81%D0%B8%D0%B8
const cities = [
    {
        name: 'Москва',
        parent: 'RU-MOW',
        coordinates: [55.75, 37.62],
    },
    {
        name: 'Санкт-Петербург',
        parent: 'RU-SPE',
        coordinates: [59.94, 30.31],
    },
    {
        name: 'Новосибирск',
        parent: 'RU-NVS',
        coordinates: [55.04, 82.93],
    },
    {
        name: 'Екатеринбург',
        parent: 'RU-SVE',
        coordinates: [56.85, 60.61],
    },
    {
        name: 'Нижний Новгород',
        parent: 'RU-NIZ',
        coordinates: [56.33, 44],
    },
    {
        name: 'Казань',
        parent: 'RU-TA',
        coordinates: [55.79, 49.11444],
    },
    {
        name: 'Самара',
        parent: 'RU-SAM',
        coordinates: [53.2, 50.15],
    },
    {
        name: 'Омск',
        parent: 'RU-OMS',
        coordinates: [54.99, 73.37],
    },
    {
        name: 'Челябинск',
        parent: 'RU-CHE',
        coordinates: [55.15, 61.43],
    },
    {
        name: 'Ростов-на-Дону',
        parent: 'RU-ROS',
        coordinates: [47.23, 39.72],
    },
    {
        name: 'Уфа',
        parent: 'RU-BA',
        coordinates: [54.74, 55.97],
    },
    {
        name: 'Волгоград',
        parent: 'RU-VGG',
        coordinates: [48.72, 44.5],
    },
    {
        name: 'Пермь',
        parent: 'RU-PER',
        coordinates: [58.01, 56.25],
    },
    {
        name: 'Красноярск',
        parent: 'RU-KYA',
        coordinates: [56.02, 92.87],
    },
    {
        name: 'Воронеж',
        parent: 'RU-VOR',
        coordinates: [51.67, 39.18],
    },
    {
        name: 'Саратов',
        parent: 'RU-SAR',
        coordinates: [51.54, 46.01],
    },
    {
        name: 'Краснодар',
        parent: 'RU-KDA',
        coordinates: [45.04, 38.98],
    },
    {
        name: 'Тольятти',
        parent: 'RU-SAM',
        coordinates: [53.53, 49.35],
    },
    {
        name: 'Барнаул',
        parent: 'RU-ALT',
        coordinates: [53.36, 83.76],
    },
    {
        name: 'Ижевск',
        parent: 'RU-UD',
        coordinates: [56.85, 53.2],
    },
    {
        name: 'Ульяновск',
        parent: 'RU-ULY',
        coordinates: [54.33, 48.39],
    },
    {
        name: 'Владивосток',
        parent: 'RU-PRI',
        coordinates: [43.11, 131.87],
    },
    {
        name: 'Ярославль',
        parent: 'RU-YAR',
        coordinates: [57.63, 39.87],
    },
    {
        name: 'Иркутск',
        parent: 'RU-IRK',
        coordinates: [52.3, 104.3],
    },
    {
        name: 'Тюмень',
        parent: 'RU-TYU',
        coordinates: [57.15, 65.53],
    },
    {
        name: 'Махачкала',
        parent: 'RU-DA',
        coordinates: [42.98, 47.5],
    },
    {
        name: 'Хабаровск',
        parent: 'RU-KHA',
        coordinates: [48.48, 135.08],
    },
    {
        name: 'Оренбург',
        parent: 'RU-ORE',
        coordinates: [51.77, 55.1],
    },
    {
        name: 'Новокузнецк',
        parent: 'RU-KEM',
        coordinates: [53.76, 87.11],
    },
    {
        name: 'Кемерово',
        parent: 'RU-KEM',
        coordinates: [55.33, 86.08],
    },
    {
        name: 'Рязань',
        parent: 'RU-RYA',
        coordinates: [54.63, 39.69],
    },
    {
        name: 'Томск',
        parent: 'RU-TOM',
        coordinates: [56.5, 84.97],
    },
    {
        name: 'Астрахань',
        parent: 'RU-AST',
        coordinates: [46.35, 48.04],
    },
    {
        name: 'Пенза',
        parent: 'RU-PNZ',
        coordinates: [53.2, 45],
    },
    {
        name: 'Набережные Челны',
        parent: 'RU-TA',
        coordinates: [55.73, 52.41],
    },
    {
        name: 'Липецк',
        parent: 'RU-LIP',
        coordinates: [52.6, 39.57],
    },
    {
        name: 'Тула',
        parent: 'RU-TUL',
        coordinates: [54.2, 37.62],
    },
    {
        name: 'Киров',
        parent: 'RU-KIR',
        coordinates: [58.6, 49.66],
    },
    {
        name: 'Чебоксары',
        parent: 'RU-CU',
        coordinates: [56.13, 47.25],
    },
    {
        name: 'Улан-Удэ',
        parent: 'RU-BU',
        coordinates: [51.83, 107.61],
    },
    {
        name: 'Калининград',
        parent: 'RU-KGD',
        coordinates: [54.71, 20.51],
    },
    {
        name: 'Брянск',
        parent: 'RU-BRY',
        coordinates: [53.25, 34.37],
    },
    {
        name: 'Курск',
        parent: 'RU-KRS',
        coordinates: [51.74, 36.19],
    },
    {
        name: 'Иваново',
        parent: 'RU-IVA',
        coordinates: [57, 40.97],
    },
    {
        name: 'Магнитогорск',
        parent: 'RU-CHE',
        coordinates: [53.42, 59.05],
    },
    {
        name: 'Тверь',
        parent: 'RU-TVE',
        coordinates: [56.86, 35.9],
    },
    {
        name: 'Ставрополь',
        parent: 'RU-STA',
        coordinates: [45.04, 41.97],
    },
    {
        name: 'Севастополь',
        parent: 'RU-SEV',
        coordinates: [44.616604, 33.525369],
    },
    {
        name: 'Нижний Тагил',
        parent: 'RU-SVE',
        coordinates: [57.92, 59.97],
    },
    {
        name: 'Белгород',
        parent: 'RU-BEL',
        coordinates: [50.61, 36.58],
    },
    {
        name: 'Архангельск',
        parent: 'RU-ARK',
        coordinates: [64.54, 40.54],
    },
    {
        name: 'Владимир',
        parent: 'RU-VLA',
        coordinates: [56.14, 40.4],
    },
    {
        name: 'Сочи',
        parent: 'RU-KDA',
        coordinates: [43.6, 39.73],
    },
    {
        name: 'Курган',
        parent: 'RU-KGN',
        coordinates: [55.45, 65.33],
    },
    {
        name: 'Симферополь',
        parent: 'RU-KRY',
        coordinates: [44.948237, 34.100318],
    },
    {
        name: 'Смоленск',
        parent: 'RU-SMO',
        coordinates: [54.78, 32.04],
    },
    {
        name: 'Калуга',
        parent: 'RU-KLU',
        coordinates: [54.53, 36.28],
    },
    {
        name: 'Чита',
        parent: 'RU-ZAB',
        coordinates: [52.03, 113.5],
    },
    {
        name: 'Саранск',
        parent: 'RU-MO',
        coordinates: [54.18, 45.17],
    },
    {
        name: 'Орёл',
        parent: 'RU-ORL',
        coordinates: [52.970371, 36.063837],
    },
    {
        name: 'Волжский',
        parent: 'RU-VGG',
        coordinates: [48.79, 44.78],
    },
    {
        name: 'Череповец',
        parent: 'RU-VLG',
        coordinates: [59.13, 37.9],
    },
    {
        name: 'Владикавказ',
        parent: 'RU-SE',
        coordinates: [43.04, 44.67],
    },
    {
        name: 'Мурманск',
        parent: 'RU-MUR',
        coordinates: [68.98, 33.09],
    },
    {
        name: 'Сургут',
        parent: 'RU-KHM',
        coordinates: [61.25, 73.42],
    },
    {
        name: 'Вологда',
        parent: 'RU-VLG',
        coordinates: [59.22, 39.88],
    },
    {
        name: 'Тамбов',
        parent: 'RU-TAM',
        coordinates: [52.73, 41.44],
    },
    {
        name: 'Стерлитамак',
        parent: 'RU-BA',
        coordinates: [53.62, 55.95],
    },
    {
        name: 'Грозный',
        parent: 'RU-CE',
        coordinates: [43.31, 45.69],
    },
    {
        name: 'Якутск',
        parent: 'RU-SA',
        coordinates: [62.03, 129.73],
    },
    {
        name: 'Кострома',
        parent: 'RU-KOS',
        coordinates: [57.77, 40.93],
    },
    {
        name: 'Комсомольск-на-Амуре',
        parent: 'RU-KHA',
        coordinates: [50.55, 137.01],
    },
    {
        name: 'Петрозаводск',
        parent: 'RU-KR',
        coordinates: [61.78, 34.35],
    },
    {
        name: 'Таганрог',
        parent: 'RU-KDA',
        coordinates: [47.24, 38.9],
    },
    {
        name: 'Нижневартовск',
        parent: 'RU-KHM',
        coordinates: [60.93, 76.55],
    },
    {
        name: 'Йошкар-Ола',
        parent: 'RU-ME',
        coordinates: [56.64, 47.89],
    },
    {
        name: 'Братск',
        parent: 'RU-IRK',
        coordinates: [56.13, 101.61],
    },
    {
        name: 'Новороссийск',
        parent: 'RU-KDA',
        coordinates: [44.72, 37.77],
    },
    {
        name: 'Дзержинск',
        parent: 'RU-NIZ',
        coordinates: [56.24, 43.46],
    },
    {
        name: 'Шахты',
        parent: 'RU-ROS',
        coordinates: [47.71, 40.21],
    },
    {
        name: 'Нальчик',
        parent: 'RU-KB',
        coordinates: [43.5, 43.62],
    },
    {
        name: 'Орск',
        parent: 'RU-ORE',
        coordinates: [51.2, 58.57],
    },
    {
        name: 'Сыктывкар',
        parent: 'RU-KO',
        coordinates: [61.68, 50.81],
    },
    {
        name: 'Нижнекамск',
        parent: 'RU-TA',
        coordinates: [55.64, 51.82],
    },
    {
        name: 'Ангарск',
        parent: 'RU-IRK',
        coordinates: [52.54, 103.89],
    },
    {
        name: 'Старый Оскол',
        parent: 'RU-BEL',
        coordinates: [51.3, 37.84],
    },
    {
        name: 'Великий Новгород',
        parent: 'RU-NGR',
        coordinates: [58.52, 31.27],
    },
    {
        name: 'Балашиха',
        parent: 'RU-MOS',
        coordinates: [55.81, 37.96],
    },
    {
        name: 'Благовещенск',
        parent: 'RU-AMU',
        coordinates: [50.28, 127.54],
    },
    {
        name: 'Прокопьевск',
        parent: 'RU-KEM',
        coordinates: [53.91, 86.72],
    },
    {
        name: 'Химки',
        parent: 'RU-MOS',
        coordinates: [55.9, 37.43],
    },
    {
        name: 'Псков',
        parent: 'RU-PSK',
        coordinates: [57.81, 28.35],
    },
    {
        name: 'Бийск',
        parent: 'RU-ALT',
        coordinates: [52.54, 85.21],
    },
    {
        name: 'Энгельс',
        parent: 'RU-SAR',
        coordinates: [51.48, 46.11],
    },
    {
        name: 'Рыбинск',
        parent: 'RU-YAR',
        coordinates: [58.04, 38.84],
    },
    {
        name: 'Балаково',
        parent: 'RU-SAR',
        coordinates: [52.03, 47.8],
    },
    {
        name: 'Северодвинск',
        parent: 'RU-ARK',
        coordinates: [64.56, 39.83],
    },
    {
        name: 'Армавир',
        parent: 'RU-KDA',
        coordinates: [44.99, 41.12],
    },
    {
        name: 'Подольск',
        parent: 'RU-MOS',
        coordinates: [55.42, 37.55],
    },
    {
        name: 'Королёв',
        parent: 'RU-MOS',
        coordinates: [55.91, 37.83],
    },
    {
        name: 'Южно-Сахалинск',
        parent: 'RU-SAK',
        coordinates: [46.95, 142.74],
    },
    {
        name: 'Петропавловск-Камчатский',
        parent: 'RU-KAM',
        coordinates: [53.04, 158.65],
    },
    {
        name: 'Сызрань',
        parent: 'RU-SAM',
        coordinates: [53.16, 48.47],
    },
    {
        name: 'Норильск',
        parent: 'RU-KYA',
        coordinates: [69.35, 88.2],
    },
    {
        name: 'Златоуст',
        parent: 'RU-CHE',
        coordinates: [55.17, 59.65],
    },
    {
        name: 'Каменск-Уральский',
        parent: 'RU-SVE',
        coordinates: [56.42, 61.93],
    },
    {
        name: 'Мытищи',
        parent: 'RU-MOS',
        coordinates: [55.91, 37.73],
    },
    {
        name: 'Люберцы',
        parent: 'RU-MOS',
        coordinates: [55.68, 37.89],
    },
    {
        name: 'Волгодонск',
        parent: 'RU-ROS',
        coordinates: [47.51, 42.15],
    },
    {
        name: 'Новочеркасск',
        parent: 'RU-ROS',
        coordinates: [47.42, 40.09],
    },
    {
        name: 'Абакан',
        parent: 'RU-KK',
        coordinates: [53.72, 91.43],
    },
    {
        name: 'Находка',
        parent: 'RU-PRI',
        coordinates: [42.81, 132.87],
    },
    {
        name: 'Уссурийск',
        parent: 'RU-PRI',
        coordinates: [43.8, 131.95],
    },
    {
        name: 'Березники',
        parent: 'RU-PER',
        coordinates: [59.41, 56.82],
    },
    {
        name: 'Салават',
        parent: 'RU-BA',
        coordinates: [53.38, 55.91],
    },
    {
        name: 'Электросталь',
        parent: 'RU-MOS',
        coordinates: [55.79, 38.45],
    },
    {
        name: 'Миасс',
        parent: 'RU-CHE',
        coordinates: [55.05, 60.11],
    },
    {
        name: 'Первоуральск',
        parent: 'RU-SVE',
        coordinates: [56.91, 59.94],
    },
    {
        name: 'Керчь',
        parent: 'RU-KRY',
        coordinates: [45.356219, 36.467378],
    },
    {
        name: 'Рубцовск',
        parent: 'RU-ALT',
        coordinates: [51.51, 81.21],
    },
    {
        name: 'Альметьевск',
        parent: 'RU-TA',
        coordinates: [54.9, 52.32],
    },
    {
        name: 'Ковров',
        parent: 'RU-VLA',
        coordinates: [56.36, 41.32],
    },
    {
        name: 'Коломна',
        parent: 'RU-MOS',
        coordinates: [55.08, 38.78],
    },
    {
        name: 'Майкоп',
        parent: 'RU-AD',
        coordinates: [44.61, 40.11],
    },
    {
        name: 'Пятигорск',
        parent: 'RU-STA',
        coordinates: [44.05, 43.06],
    },
    {
        name: 'Одинцово',
        parent: 'RU-MOS',
        coordinates: [55.68, 37.28],
    },
    {
        name: 'Копейск',
        parent: 'RU-CHE',
        coordinates: [55.12, 61.63],
    },
    {
        name: 'Хасавюрт',
        parent: 'RU-DA',
        coordinates: [43.25, 46.59],
    },
    {
        name: 'Новомосковск',
        parent: 'RU-TUL',
        coordinates: [54.01, 38.28],
    },
    {
        name: 'Кисловодск',
        parent: 'RU-STA',
        coordinates: [43.91, 42.72],
    },
    {
        name: 'Серпухов',
        parent: 'RU-MOS',
        coordinates: [54.92, 37.41],
    },
    {
        name: 'Новочебоксарск',
        parent: 'RU-CU',
        coordinates: [56.11, 47.48],
    },
    {
        name: 'Нефтеюганск',
        parent: 'RU-KHM',
        coordinates: [61.1, 72.6],
    },
    {
        name: 'Димитровград',
        parent: 'RU-ULY',
        coordinates: [54.21, 49.62],
    },
    {
        name: 'Нефтекамск',
        parent: 'RU-BA',
        coordinates: [56.09, 54.27],
    },
    {
        name: 'Черкесск',
        parent: 'RU-KC',
        coordinates: [44.22, 42.06],
    },
    {
        name: 'Орехово-Зуево',
        parent: 'RU-MOS',
        coordinates: [55.81, 38.96],
    },
    {
        name: 'Дербент',
        parent: 'RU-DA',
        coordinates: [42.07, 48.29],
    },
    {
        name: 'Камышин',
        parent: 'RU-VGG',
        coordinates: [50.1, 45.42],
    },
    {
        name: 'Невинномысск',
        parent: 'RU-STA',
        coordinates: [44.63, 41.94],
    },
    {
        name: 'Красногорск',
        parent: 'RU-MOS',
        coordinates: [55.82, 37.33],
    },
    {
        name: 'Муром',
        parent: 'RU-VLA',
        coordinates: [55.58, 42.04],
    },
    {
        name: 'Батайск',
        parent: 'RU-ROS',
        coordinates: [47.14, 39.75],
    },
    {
        name: 'Новошахтинск',
        parent: 'RU-ROS',
        coordinates: [47.76, 39.93],
    },
    {
        name: 'Сергиев Посад',
        parent: 'RU-MOS',
        coordinates: [56.3, 38.13],
    },
    {
        name: 'Ноябрьск',
        parent: 'RU-YAN',
        coordinates: [63.19, 75.44],
    },
    {
        name: 'Щёлково',
        parent: 'RU-MOS',
        coordinates: [55.93, 37.97],
    },
    {
        name: 'Кызыл',
        parent: 'RU-TY',
        coordinates: [51.71, 94.45],
    },
    {
        name: 'Октябрьский',
        parent: 'RU-BA',
        coordinates: [54.48, 53.47],
    },
    {
        name: 'Ачинск',
        parent: 'RU-KYA',
        coordinates: [56.27, 90.5],
    },
    {
        name: 'Северск',
        parent: 'RU-TOM',
        coordinates: [56.6, 84.89],
    },
    {
        name: 'Новокуйбышевск',
        parent: 'RU-SAM',
        coordinates: [53.1, 49.95],
    },
    {
        name: 'Елец',
        parent: 'RU-LIP',
        coordinates: [52.62, 38.5],
    },
    {
        name: 'Арзамас',
        parent: 'RU-NIZ',
        coordinates: [55.39, 43.84],
    },
    {
        name: 'Евпатория',
        parent: 'RU-KRY',
        coordinates: [45.190629, 33.367634],
    },
    {
        name: 'Обнинск',
        parent: 'RU-KLU',
        coordinates: [55.1, 36.61],
    },
    {
        name: 'Новый Уренгой',
        parent: 'RU-YAN',
        coordinates: [66.08, 76.63],
    },
    {
        name: 'Каспийск',
        parent: 'RU-DA',
        coordinates: [42.88, 47.64],
    },
    {
        name: 'Элиста',
        parent: 'RU-KL',
        coordinates: [46.31, 44.26],
    },
    {
        name: 'Пушкино',
        parent: 'RU-MOS',
        coordinates: [56.02, 37.87],
    },
    {
        name: 'Жуковский',
        parent: 'RU-MOS',
        coordinates: [55.6, 38.12],
    },
    {
        name: 'Артём',
        parent: 'RU-PRI',
        coordinates: [43.36, 132.19],
    },
    {
        name: 'Междуреченск',
        parent: 'RU-KEM',
        coordinates: [53.69, 88.06],
    },
    {
        name: 'Ленинск-Кузнецкий',
        parent: 'RU-KEM',
        coordinates: [54.66, 86.17],
    },
    {
        name: 'Сарапул',
        parent: 'RU-UD',
        coordinates: [56.48, 53.8],
    },
    {
        name: 'Ессентуки',
        parent: 'RU-STA',
        coordinates: [44.04, 42.86],
    },
    {
        name: 'Воткинск',
        parent: 'RU-UD',
        coordinates: [57.05, 53.99],
    },
    {
        name: 'Ногинск',
        parent: 'RU-MOS',
        coordinates: [55.87, 38.44],
    },
    {
        name: 'Тобольск',
        parent: 'RU-TYU',
        coordinates: [58.2, 68.25],
    },
    {
        name: 'Ухта',
        parent: 'RU-KO',
        coordinates: [63.57, 53.68],
    },
    {
        name: 'Серов',
        parent: 'RU-SVE',
        coordinates: [59.6, 60.58],
    },
    {
        name: 'Бердск',
        parent: 'RU-NVS',
        coordinates: [54.76, 83.1],
    },
    {
        name: 'Великие Луки',
        parent: 'RU-PSK',
        coordinates: [56.34, 30.55],
    },
    {
        name: 'Мичуринск',
        parent: 'RU-TAM',
        coordinates: [52.9, 40.49],
    },
    {
        name: 'Киселёвск',
        parent: 'RU-KEM',
        coordinates: [53.99, 86.66],
    },
    {
        name: 'Новотроицк',
        parent: 'RU-ORE',
        coordinates: [51.2, 58.33],
    },
    {
        name: 'Зеленодольск',
        parent: 'RU-TA',
        coordinates: [55.84, 48.52],
    },
    {
        name: 'Соликамск',
        parent: 'RU-PER',
        coordinates: [59.63, 56.77],
    },
    {
        name: 'Раменское',
        parent: 'RU-MOS',
        coordinates: [55.57, 38.23],
    },
    {
        name: 'Домодедово',
        parent: 'RU-MOS',
        coordinates: [55.44, 37.75],
    },
    {
        name: 'Магадан',
        parent: 'RU-MAG',
        coordinates: [59.56, 150.8],
    },
    {
        name: 'Глазов',
        parent: 'RU-UD',
        coordinates: [58.14, 52.66],
    },
    {
        name: 'Каменск-Шахтинский',
        parent: 'RU-ROS',
        coordinates: [48.320515, 40.268923],
    },
    {
        name: 'Железногорск',
        parent: 'RU-KRS',
        coordinates: [56.25, 93.53],
    },
    {
        name: 'Канск',
        parent: 'RU-KYA',
        coordinates: [56.2, 95.72],
    },
    {
        name: 'Назрань',
        parent: 'RU-IN',
        coordinates: [43.23, 44.77],
    },
    {
        name: 'Гатчина',
        parent: 'RU-LEN',
        coordinates: [59.58, 30.13],
    },
    {
        name: 'Саров',
        parent: 'RU-NIZ',
        coordinates: [54.94, 43.32],
    },
    {
        name: 'Новоуральск',
        parent: 'RU-SVE',
        coordinates: [57.24, 60.08],
    },
    {
        name: 'Воскресенск',
        parent: 'RU-MOS',
        coordinates: [55.32, 38.65],
    },
    {
        name: 'Долгопрудный',
        parent: 'RU-MOS',
        coordinates: [55.95, 37.5],
    },
    {
        name: 'Бугульма',
        parent: 'RU-TA',
        coordinates: [54.54, 52.8],
    },
    {
        name: 'Кузнецк',
        parent: 'RU-PNZ',
        coordinates: [53.12, 46.6],
    },
    {
        name: 'Губкин',
        parent: 'RU-BEL',
        coordinates: [51.28, 37.55],
    },
    {
        name: 'Кинешма',
        parent: 'RU-IVA',
        coordinates: [57.44, 42.13],
    },
    {
        name: 'Ейск',
        parent: 'RU-KDA',
        coordinates: [46.71, 38.27],
    },
    {
        name: 'Реутов',
        parent: 'RU-MOS',
        coordinates: [55.76, 37.86],
    },
    {
        name: 'Усть-Илимск',
        parent: 'RU-IRK',
        coordinates: [58, 102.66],
    },
    {
        name: 'Железногорск-Илимский',
        parent: 'RU-IRK',
        coordinates: [56.25, 93.53],
    },
    {
        name: 'Усолье-Сибирское',
        parent: 'RU-KYA',
        coordinates: [52.75, 103.65],
    },
    {
        name: 'Чайковский',
        parent: 'RU-PER',
        coordinates: [56.77, 54.11],
    },
    {
        name: 'Азов',
        parent: 'RU-ROS',
        coordinates: [47.11, 39.42],
    },
    {
        name: 'Бузулук',
        parent: 'RU-ORE',
        coordinates: [52.78, 52.26],
    },
    {
        name: 'Озёрск',
        parent: 'RU-CHE',
        coordinates: [55.76, 60.7],
    },
    {
        name: 'Балашов',
        parent: 'RU-SAR',
        coordinates: [51.55, 43.17],
    },
    {
        name: 'Юрга',
        parent: 'RU-KEM',
        coordinates: [55.713557, 84.933869],
    },
    {
        name: 'Кирово-Чепецк',
        parent: 'RU-KIR',
        coordinates: [58.55, 50.04],
    },
    {
        name: 'Кропоткин',
        parent: 'RU-KDA',
        coordinates: [45.44, 40.58],
    },
    {
        name: 'Клин',
        parent: 'RU-MOS',
        coordinates: [56.33, 36.73],
    },
    {
        name: 'Выборг',
        parent: 'RU-LEN',
        coordinates: [60.71, 28.75],
    },
    {
        name: 'Ханты-Мансийск',
        parent: 'RU-KHM',
        coordinates: [61, 69],
    },
    {
        name: 'Троицк',
        parent: 'RU-CHE',
        coordinates: [54.1, 61.58],
    },
    {
        name: 'Бор',
        parent: 'RU-NIZ',
        coordinates: [56.36, 44.07],
    },
    {
        name: 'Шадринск',
        parent: 'RU-KGN',
        coordinates: [56.09, 63.63],
    },
    {
        name: 'Белово',
        parent: 'RU-KEM',
        coordinates: [54.42, 86.3],
    },
    {
        name: 'Ялта',
        parent: 'RU-KRY',
        coordinates: [44.496418, 34.169775],
    },
    {
        name: 'Минеральные Воды',
        parent: 'RU-STA',
        coordinates: [44.21, 43.14],
    },
    {
        name: 'Анжеро-Судженск',
        parent: 'RU-KEM',
        coordinates: [56.08, 86.03],
    },
    {
        name: 'Биробиджан',
        parent: 'RU-YEV',
        coordinates: [48.79, 132.92],
    },
    {
        name: 'Лобня',
        parent: 'RU-MOS',
        coordinates: [56.01, 37.48],
    },
    {
        name: 'Новоалтайск',
        parent: 'RU-ALT',
        coordinates: [53.39, 83.94],
    },
    {
        name: 'Чапаевск',
        parent: 'RU-SAM',
        coordinates: [52.98, 49.71],
    },
    {
        name: 'Георгиевск',
        parent: 'RU-STA',
        coordinates: [44.15, 43.47],
    },
    {
        name: 'Черногорск',
        parent: 'RU-KK',
        coordinates: [53.82, 91.28],
    },
    {
        name: 'Минусинск',
        parent: 'RU-KYA',
        coordinates: [53.71, 91.69],
    },
    {
        name: 'Михайловск',
        parent: 'RU-STA',
        coordinates: [45.13, 42.03],
    },
    {
        name: 'Елабуга',
        parent: 'RU-TA',
        coordinates: [55.76, 52.06],
    },
    {
        name: 'Дубна',
        parent: 'RU-MOS',
        coordinates: [56.73, 37.17],
    },
    {
        name: 'Воркута',
        parent: 'RU-KO',
        coordinates: [67.5, 64.05],
    },
    {
        name: 'Егорьевск',
        parent: 'RU-MOS',
        coordinates: [55.38, 39.03],
    },
    {
        name: 'Асбест',
        parent: 'RU-SVE',
        coordinates: [57.01, 61.46],
    },
    {
        name: 'Ишим',
        parent: 'RU-TYU',
        coordinates: [56.11, 69.49],
    },
    {
        name: 'Феодосия',
        parent: 'RU-KRY',
        coordinates: [45.030209, 35.383822],
    },
    {
        name: 'Белорецк',
        parent: 'RU-BA',
        coordinates: [53.96, 58.4],
    },
    {
        name: 'Белогорск',
        parent: 'RU-AMU',
        coordinates: [50.92, 128.48],
    },
    {
        name: 'Кунгур',
        parent: 'RU-PER',
        coordinates: [57.44, 56.96],
    },
    {
        name: 'Лысьва',
        parent: 'RU-PER',
        coordinates: [58.11, 57.81],
    },
    {
        name: 'Гуково',
        parent: 'RU-ROS',
        coordinates: [48.06, 39.94],
    },
    {
        name: 'Ступино',
        parent: 'RU-MOS',
        coordinates: [54.9, 38.07],
    },
    {
        name: 'Туймазы',
        parent: 'RU-BA',
        coordinates: [54.61, 53.71],
    },
    {
        name: 'Кстово',
        parent: 'RU-NIZ',
        coordinates: [56.15, 44.2],
    },
    {
        name: 'Вольск',
        parent: 'RU-SAR',
        coordinates: [52.05, 47.38],
    },
    {
        name: 'Ишимбай',
        parent: 'RU-BA',
        coordinates: [53.45, 56.04],
    },
    {
        name: 'Зеленогорск',
        parent: 'RU-KYA',
        coordinates: [56.11, 94.6],
    },
    {
        name: 'Сосновый Бор',
        parent: 'RU-LEN',
        coordinates: [59.9, 29.09],
    },
    {
        name: 'Буйнакск',
        parent: 'RU-DA',
        coordinates: [42.82, 47.12],
    },
    {
        name: 'Борисоглебск',
        parent: 'RU-VOR',
        coordinates: [51.37, 42.08],
    },
    {
        name: 'Наро-Фоминск',
        parent: 'RU-MOS',
        coordinates: [55.39, 36.73],
    },
    {
        name: 'Будённовск',
        parent: 'RU-STA',
        coordinates: [44.78, 44.17],
    },
    {
        name: 'Донской',
        parent: 'RU-TUL',
        coordinates: [53.96787, 38.337222],
    },
    {
        name: 'Сунжа',
        parent: 'RU-IN',
        coordinates: [43.32, 45.05],
    },
    {
        name: 'Полевской',
        parent: 'RU-SVE',
        coordinates: [56.44, 60.19],
    },
    {
        name: 'Лениногорск',
        parent: 'RU-TA',
        coordinates: [54.6, 52.46],
    },
    {
        name: 'Павловский Посад',
        parent: 'RU-MOS',
        coordinates: [55.78, 38.65],
    },
    {
        name: 'Славянск-на-Кубани',
        parent: 'RU-KDA',
        coordinates: [45.26, 38.13],
    },
    {
        name: 'Заречный',
        parent: 'RU-PNZ',
        coordinates: [53.13, 46.58],
    },
    {
        name: 'Туапсе',
        parent: 'RU-KDA',
        coordinates: [44.11, 39.08],
    },
    {
        name: 'Россошь',
        parent: 'RU-VOR',
        coordinates: [51.12, 38.51],
    },
    {
        name: 'Горно-Алтайск',
        parent: 'RU-AL',
        coordinates: [51.96, 85.92],
    },
    {
        name: 'Кумертау',
        parent: 'RU-BA',
        coordinates: [52.77, 55.78],
    },
    {
        name: 'Лабинск',
        parent: 'RU-KDA',
        coordinates: [44.63, 40.74],
    },
    {
        name: 'Сибай',
        parent: 'RU-BA',
        coordinates: [52.72, 58.67],
    },
    {
        name: 'Клинцы',
        parent: 'RU-BRY',
        coordinates: [52.76, 32.24],
    },
    {
        name: 'Ржев',
        parent: 'RU-TVE',
        coordinates: [56.26, 34.33],
    },
    {
        name: 'Тихорецк',
        parent: 'RU-KDA',
        coordinates: [45.85, 40.13],
    },
    {
        name: 'Нерюнгри',
        parent: 'RU-SA',
        coordinates: [56.657717, 124.712302],
    },
    {
        name: 'Алексин',
        parent: 'RU-TUL',
        coordinates: [54.51, 37.07],
    },
    {
        name: 'Ревда',
        parent: 'RU-SVE',
        coordinates: [56.8, 59.93],
    },
    {
        name: 'Александров',
        parent: 'RU-VLA',
        coordinates: [56.4, 38.71],
    },
    {
        name: 'Дмитров',
        parent: 'RU-MOS',
        coordinates: [56.34, 37.52],
    },
    {
        name: 'Мелеуз',
        parent: 'RU-BA',
        coordinates: [52.96, 55.93],
    },
    {
        name: 'Сальск',
        parent: 'RU-ROS',
        coordinates: [46.47, 41.54],
    },
    {
        name: 'Лесосибирск',
        parent: 'RU-KYA',
        coordinates: [58.24, 92.48],
    },
    {
        name: 'Гусь-Хрустальный',
        parent: 'RU-VLA',
        coordinates: [55.61, 40.65],
    },
    {
        name: 'Чистополь',
        parent: 'RU-TA',
        coordinates: [55.36, 50.64],
    },
    {
        name: 'Павлово',
        parent: 'RU-NIZ',
        coordinates: [55.97, 43.09],
    },
    {
        name: 'Чехов',
        parent: 'RU-MOS',
        coordinates: [55.15, 37.48],
    },
    {
        name: 'Котлас',
        parent: 'RU-ARK',
        coordinates: [61.26, 46.65],
    },
    {
        name: 'Белебей',
        parent: 'RU-BA',
        coordinates: [54.12, 54.12],
    },
    {
        name: 'Искитим',
        parent: 'RU-NVS',
        coordinates: [54.64, 83.3],
    },
    {
        name: 'Краснотурьинск',
        parent: 'RU-SVE',
        coordinates: [59.77, 60.21],
    },
    {
        name: 'Апатиты',
        parent: 'RU-MUR',
        coordinates: [67.56, 33.4],
    },
    {
        name: 'Всеволожск',
        parent: 'RU-LEN',
        coordinates: [60.021317, 30.654084],
    },
    {
        name: 'Прохладный',
        parent: 'RU-KB',
        coordinates: [43.76, 44.03],
    },
    {
        name: 'Михайловка',
        parent: 'RU-VGG',
        coordinates: [50.06, 43.24],
    },
    {
        name: 'Анапа',
        parent: 'RU-KDA',
        coordinates: [44.89, 37.32],
    },
    {
        name: 'Тихвин',
        parent: 'RU-LEN',
        coordinates: [59.65, 33.53],
    },
    {
        name: 'Верхняя Пышма',
        parent: 'RU-SVE',
        coordinates: [56.97, 60.58],
    },
    {
        name: 'Ивантеевка',
        parent: 'RU-MOS',
        coordinates: [55.97, 37.92],
    },
    {
        name: 'Свободный',
        parent: 'RU-AMU',
        coordinates: [51.38, 128.14],
    },
    {
        name: 'Шуя',
        parent: 'RU-IVA',
        coordinates: [56.85, 41.39],
    },
    {
        name: 'Когалым',
        parent: 'RU-KHM',
        coordinates: [62.27, 74.48],
    },
    {
        name: 'Щёкино',
        parent: 'RU-TUL',
        coordinates: [54.002146, 37.517626],
    },
    {
        name: 'Жигулёвск',
        parent: 'RU-SAM',
        coordinates: [53.401714, 49.494657],
    },
    {
        name: 'Крымск',
        parent: 'RU-KDA',
        coordinates: [44.93, 37.99],
    },
    {
        name: 'Вязьма',
        parent: 'RU-SMO',
        coordinates: [55.21, 34.3],
    },
    {
        name: 'Видное',
        parent: 'RU-MOS',
        coordinates: [55.55, 37.71],
    },
    {
        name: 'Арсеньев',
        parent: 'RU-PRI',
        coordinates: [44.15, 133.28],
    },
    {
        name: 'Избербаш',
        parent: 'RU-DA',
        coordinates: [42.565141, 47.871078],
    },
    {
        name: 'Выкса',
        parent: 'RU-NIZ',
        coordinates: [55.32, 42.17],
    },
    {
        name: 'Лиски',
        parent: 'RU-VOR',
        coordinates: [50.98, 39.52],
    },
    {
        name: 'Волжск',
        parent: 'RU-ME',
        coordinates: [55.87, 48.36],
    },
    {
        name: 'Краснокаменск',
        parent: 'RU-ZAB',
        coordinates: [50.1, 118.04],
    },
    {
        name: 'Фрязино',
        parent: 'RU-MOS',
        coordinates: [55.96, 38.05],
    },
    {
        name: 'Узловая',
        parent: 'RU-TUL',
        coordinates: [53.98, 38.17],
    },
    {
        name: 'Лыткарино',
        parent: 'RU-MOS',
        coordinates: [55.58, 37.91],
    },
    {
        name: 'Нягань',
        parent: 'RU-KHM',
        coordinates: [62.14, 65.39],
    },
    {
        name: 'Рославль',
        parent: 'RU-SMO',
        coordinates: [53.95, 32.86],
    },
    {
        name: 'Геленджик',
        parent: 'RU-KDA',
        coordinates: [44.56, 38.08],
    },
    {
        name: 'Боровичи',
        parent: 'RU-NGR',
        coordinates: [58.39, 33.92],
    },
    {
        name: 'Тимашёвск',
        parent: 'RU-KDA',
        coordinates: [45.62, 38.95],
    },
    {
        name: 'Белореченск',
        parent: 'RU-KDA',
        coordinates: [44.77, 39.88],
    },
    {
        name: 'Солнечногорск',
        parent: 'RU-MOS',
        coordinates: [56.18, 36.98],
    },
    {
        name: 'Назарово',
        parent: 'RU-KYA',
        coordinates: [56.01, 90.4],
    },
    {
        name: 'Кириши',
        parent: 'RU-LEN',
        coordinates: [59.45, 32.02],
    },
    {
        name: 'Черемхово',
        parent: 'RU-IRK',
        coordinates: [53.16, 103.07],
    },
    {
        name: 'Краснокамск',
        parent: 'RU-PER',
        coordinates: [58.08, 55.76],
    },
    {
        name: 'Лесной',
        parent: 'RU-SVE',
        coordinates: [57.62, 63.08],
    },
    {
        name: 'Вышний Волочёк',
        parent: 'RU-TVE',
        coordinates: [57.584174, 34.558836],
    },
    {
        name: 'Бугуруслан',
        parent: 'RU-ORE',
        coordinates: [53.66, 52.44],
    },
    {
        name: 'Берёзовский',
        parent: 'RU-SVE',
        coordinates: [55.6, 86.2],
    },
    {
        name: 'Балахна',
        parent: 'RU-NIZ',
        coordinates: [56.49, 43.6],
    },
    {
        name: 'Ливны',
        parent: 'RU-ORL',
        coordinates: [52.43, 37.61],
    },
    {
        name: 'Донецк',
        parent: 'RU-ROS',
        coordinates: [48.34, 39.96],
    },
    {
        name: 'Североморск',
        parent: 'RU-MUR',
        coordinates: [69.07, 33.42],
    },
    {
        name: 'Саяногорск',
        parent: 'RU-KK',
        coordinates: [53.100762, 91.412195],
    },
    {
        name: 'Салехард',
        parent: 'RU-YAN',
        coordinates: [66.53, 66.6],
    },
    {
        name: 'Гатчина',
        parent: 'RU-LEN',
        coordinates: [59.58, 30.13],
    },
    {
        name: 'Назрань',
        parent: 'RU-IN',
        coordinates: [43.23, 44.77],
    },
    {
        name: 'Анадырь',
        parent: 'RU-CHU',
        coordinates: [64.73, 177.51],
    },
    {
        name: 'Нарьян-Мар',
        parent: 'RU-NEN',
        coordinates: [67.64, 53],
    },
];

export default cities;
