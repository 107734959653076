import Modal from "antd/lib/modal";
import React, { useState } from "react";
import styled from "styled-components";

const DisclaimerText = React.lazy(() => import("./DisclaimerText"));

const DisclaimerWrapper = styled.div`
  margin: 0.5rem;
`;

const DisclaimerLink = styled.span`
  text-align: center;
  cursor: pointer;
  color: rgb(0, 143, 251);
`;

const Disclaimer = () => {
  const [visible, setVisible] = useState(false);

  return (
    <DisclaimerWrapper>
      <DisclaimerLink onClick={() => setVisible(true)}>Правовая информация</DisclaimerLink>
      <Modal visible={visible} onCancel={() => setVisible(false)} footer={null} width={1024}>
        <DisclaimerText />
      </Modal>
    </DisclaimerWrapper>
  );
};

export default React.memo(Disclaimer);
