import styled from "styled-components";
import { device } from "../../util/mediaQueries";

export const MapWrapper = styled.div`
  position: relative;
  height: 100%;
`;

export const MapFooterContainer = styled.div`
  background: rgba(255, 255, 255, 0.65);
  display: flex;
  justify-content: space-between;
  position: absolute;
  bottom: 0;
  width: 100%;
  padding: 8px 10px 8px;
  color: #4d4d4d;
`;

export const MapFooterColumn = styled.div``;

export const MapFooterSourceInformation = styled.p`
  display: inline-block;
  max-width: 250px;
  font-size: 12px;
  margin-bottom: auto;
`;

export const MapLegendContainer = styled.div`
  display: flex;
  @media ${device.laptopM} {
    align-items: center;
  }
`;

export const MapLegendMarker = styled.div`
  font-size: 11px;
  font-weight: bold;
  padding: 4px 2px;
  order: 1;
  display: flex;
  align-items: flex-start;
`;

export const MapLegendTip = styled.div`
  line-height: 120%;
  font-size: 12px;
`;
export const MapGradientContainer = styled.div``;

export const MapGradientBar = styled.div<{ background: string }>`
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 6px;
  margin-bottom: 4px;
  background-image: ${({ background }) => background};
`;

export const MapGradientBarPoint = styled.div`
  height: 10px;
  width: 1px;
  background-color: #000;
  position: relative;
`;

export const MapGradientLabels = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: 10px;
`;
