import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import { selectRegionByCode } from "../store/regions/regions.selector";
import { fetchCitiesStatistics } from "../store/statistics/statistics.reducer";
import { FilterParams } from "./filters.hooks";

type RegionHookProps = {
  region_id: number;
  filter: FilterParams;
};

export const useRegionById = ({ region_id, filter }: RegionHookProps) => {
  const dispatch = useDispatch();

  useEffect(() => {
    if (!region_id) return;
    dispatch(
      fetchCitiesStatistics({
        filters: filter,
        region_id: region_id,
      })
    );
  }, [region_id, filter, dispatch]);
};

export const useRegion = () => {
  const params = useParams<{ region: string }>();
  const region = useSelector(selectRegionByCode(params.region));

  return region;
};
