import axios from "axios";
import { StatisticDataType } from "../store/statistics/statistics.reducer";
import { IOptions } from "../types/NetworkState";
import { filterKey } from "../util/filterKeys";

type Parameter = "skills" | "subjectareas" | "specials";

const fetchByTop = async (parameter: Parameter, options: IOptions) => {
  const { mapMode, filter, filters, limit, region_id } = options;

  let url = "/top";

  if (mapMode === "city") {
    url = "/topintown";
  }

  if (mapMode === "region") {
    url = "/topinreg";
  }

  try {
    const response = await axios.post<StatisticDataType[]>(url, {
      parameter,
      orderby: filter,
      limit: limit || 10,
      region_id: region_id,
      [filterKey.skill]: filters[filterKey.skill],
      [filterKey.field]: filters[filterKey.field],
      [filterKey.position]: filters[filterKey.position],
    });
    return Array.isArray(response.data) ? response.data : [];
  } catch (error) {
    throw new Error("Ошибка при загрузке данных");
  }
};

const fetchByCountry = async (options: IOptions) => {
  try {
    const response = await axios.post<StatisticDataType[]>("/regionlist", {
      [filterKey.skill]: options.filters[filterKey.skill],
      [filterKey.field]: options.filters[filterKey.field],
      [filterKey.position]: options.filters[filterKey.position],
    });
    return Array.isArray(response.data) ? response.data : [];
  } catch (error) {
    throw new Error("Ошибка при загрузке данных");
  }
};

const fetchByRegion = async (options: IOptions) => {
  try {
    const response = await axios.post<StatisticDataType[]>("/regionstat", {
      region_id: options.region_id,
      [filterKey.skill]: options.filters[filterKey.skill],
      [filterKey.field]: options.filters[filterKey.field],
      [filterKey.position]: options.filters[filterKey.position],
    });
    return Array.isArray(response.data) ? response.data : [];
  } catch (error) {
    throw new Error("Ошибка при загрузке данных");
  }
};

export const StatisticAPI = { fetchByTop, fetchByCountry, fetchByRegion };
