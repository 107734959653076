import React from "react";
import { RatingLegend, RatingLegendItem } from "./Rating.styled";

const LegendChart = () => {
  return (
    <RatingLegend>
      <RatingLegendItem color="#26a0fc">
        Количество вакансий без удалённой работы (ед.)
      </RatingLegendItem>
      <RatingLegendItem color="#1585da">
        Количество вакансий для удалённой работы (ед.)
      </RatingLegendItem>
      <RatingLegendItem color="#26e7a6">Средняя заработная плата (руб.)</RatingLegendItem>
      <RatingLegendItem color="#d0d0d0">НД - нет данных</RatingLegendItem>
    </RatingLegend>
  );
};

export default React.memo(LegendChart);
