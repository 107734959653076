import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { PositionAPI } from "../../services/positions.services";
import { IState } from "../../types/NetworkState";

export interface IPosition {
  id: number;
  parent_id: number;
  s_name: string;
  uid: string;
}

const initialState: IState<IPosition> = {
  duration: 0,
  entities: [],
  loading: "idle",
};

export const fetchPositions = createAsyncThunk("positions/fetch", async () => {
  const entities = await PositionAPI.fetch();
  return {
    entities,
  };
});

const positionsSlice = createSlice({
  name: "positions",
  initialState,
  reducers: {
    setPositions(state, action: PayloadAction<IPosition[]>) {
      return { ...state, entities: action.payload };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchPositions.fulfilled, (state, action) => {
        return { ...state, ...action.payload, loading: "done" };
      })
      .addCase(fetchPositions.pending, (state) => {
        return { ...state, loading: "pending" };
      });
  },
});

const { actions, reducer } = positionsSlice;

export const { setPositions } = actions;

export default reducer;
