import React from "react";
import Dropdown from "antd/lib/dropdown";
import Button from "antd/lib/button";
import Menu from "antd/lib/menu";
import EllipsisOutlined from "@ant-design/icons/lib/icons/EllipsisOutlined";
import styled from "styled-components";

import Filters from "../Filter/Filters";

import {
  HeaderBanners,
  HeaderImageLogo,
  HeaderLogo,
  HeaderSwitch,
  HeaderTextLogo,
  HeaderTextLogoName,
  HeaderWrapper,
} from "./Header.styled";

import LogoPNG from "../../assets/logo.png";
import LogoSVG from "../../assets/logo.svg";
import ZkLogo from "../../assets/zk-logo.png";

import { device } from "../../util/mediaQueries";
import ServicesDropdown from "../ServicesDropdown";

const SButton = styled(Button)`
  font-weight: 500;
  margin-right: 5px;
  span:last-child {
    display: none;
    @media ${device.tablet} {
      display: inline-block;
    }
  }
`;

const isRedesign = process.env.REACT_APP_THEME === "redesign";

const Header = () => {
  return (
    <HeaderWrapper>
      <HeaderLogo>
        {isRedesign ? (
          <HeaderImageLogo>
            <img className="Header-info__logo" src={LogoSVG} alt="" />
          </HeaderImageLogo>
        ) : (
          <HeaderTextLogo>
            <HeaderTextLogoName title="Сервис аналитики рынка труда в сфере ИТ (вакансии, компетенции)">
              БМКарта<b>{process.env.REACT_APP_NAME}</b>
              <sup>
                <i>beta</i>
              </sup>
            </HeaderTextLogoName>
          </HeaderTextLogo>
        )}
        <HeaderSwitch>
          <Dropdown overlay={ServicesDropdown} trigger={["click", "hover"]}>
            <SButton type="ghost" shape="round" icon={<EllipsisOutlined />}>
              Сервисы
            </SButton>
          </Dropdown>
        </HeaderSwitch>
      </HeaderLogo>
      <Filters />
      <HeaderBanners>
        <img src={ZkLogo} alt="" height={36} />
        <img src={LogoPNG} alt="" height={36} />
      </HeaderBanners>
    </HeaderWrapper>
  );
};

export default React.memo(Header);
