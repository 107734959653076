import React, { useCallback, useMemo } from "react";
import { useSelector } from "react-redux";
import { RouteComponentProps } from "react-router-dom";

import {
  selectCity,
  selectFieldsStatistics,
  selectPositionsStatistics,
  selectSkillsStatistics,
} from "../../store/statistics/statistics.selectors";

import {
  fetchFieldsStatistics,
  fetchPositionsStatistics,
  fetchSkillsStatistics,
} from "../../store/statistics/statistics.reducer";

import { MapMode } from "../../types/MapMode";
import { useRegion } from "../../hooks/region.hooks";
import { useFilters } from "../../hooks/filters.hooks";
import { filterKey } from "../../util/filterKeys";

import RegionChart from "../Charts/RegionChart";
import RegularChart from "../Charts/RegularChart";
import SidebarTitle from "./SidebarTitle";

type RouteParams = {
  region: string;
};

const Sidebar: React.FC<RouteComponentProps<RouteParams>> = ({ history }) => {
  const [{ search, filter }, setFilter] = useFilters();
  const region = useRegion();
  const city = useSelector(selectCity(search.get("city")));

  const handleBackToCountry = useCallback(() => {
    const queryString = search.toString();
    // Перемещаемся на главную, с сохранением фильтров
    if (queryString) {
      history.push(`/?${queryString}`);
    } else {
      history.push(`/`);
    }
  }, [history, search]);

  const handleBackToRegion = useCallback(() => {
    if (!region) return;
    // Убираем city из фильтров
    setFilter("city");
  }, [region, setFilter]);

  const mapMode = useMemo<MapMode>(() => {
    if (city?.res_id) return "city";
    if (region?.res_id) return "region";
    return "country";
  }, [city?.res_id, region?.res_id]);

  const chartProps = {
    region,
    city,
    mapMode,
    filter,
    setFilter,
  };

  return (
    <>
      <SidebarTitle
        city={city}
        region={region}
        onBackToCountry={handleBackToCountry}
        onBackToRegion={handleBackToRegion}
      />
      {!city && <RegionChart {...chartProps} searchParams={search} />}
      <RegularChart
        {...chartProps}
        filterKey={filterKey.skill}
        title="Требуемые технологии/компетенции на рынке труда"
        selector={selectSkillsStatistics}
        action={fetchSkillsStatistics}
      />
      <RegularChart
        {...chartProps}
        filterKey={filterKey.position}
        title="Востребованность профессий на рынке труда"
        selector={selectPositionsStatistics}
        action={fetchPositionsStatistics}
      />
      <RegularChart
        {...chartProps}
        filterKey={filterKey.field}
        title="Востребованность по отраслям компании"
        selector={selectFieldsStatistics}
        action={fetchFieldsStatistics}
      />
    </>
  );
};

export default React.memo(Sidebar, (prev, next) => {
  return prev.match.params.region === next.match.params.region;
});
