import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { fetchRegions } from "../regions/regions.reducer";

export interface IAppState {
  isLoading: string;
}

const initialState: IAppState = {
  isLoading: "",
};

export const fetchAllData = createAsyncThunk("app/data", async (_, thunkAPI) => {
  const dispatch = thunkAPI.dispatch;
  dispatch(loading("Загрузка списка регионов"));
  dispatch(fetchRegions());
  dispatch(loading(false));
});

// TODO: в loading опционально добавить label, убирать если loading = false;
const appSlice = createSlice({
  name: "app",
  initialState,
  reducers: {
    loading(state, action: PayloadAction<boolean | string>) {
      switch (typeof action.payload) {
        case "boolean":
          return { isLoading: action.payload ? "Загрузка данных" : "" };
        case "string":
          return { isLoading: action.payload };
        default:
          return state;
      }
    },
  },
});

const { actions, reducer } = appSlice;

export const { loading } = actions;

export default reducer;
