import React, { useCallback } from "react";
import { StatisticDataType } from "../../store/statistics/statistics.reducer";
import {
  RatingItemChart,
  RatingItemChartBar,
  RatingItemChartWrapper,
  RatingItemName,
  RatingItemWrapper,
} from "./Rating.styled";

type RatingItemProps = {
  bar: StatisticDataType;
  maxDemand: number;
  maxSalary: number;
  onSelect: (rowId: string | number) => void;
  rowId?: keyof StatisticDataType;
};

const RatingItem: React.FC<RatingItemProps> = ({ bar, maxDemand, maxSalary, onSelect, rowId }) => {
  const demandPercentWidth = Math.ceil((bar.result_demand / maxDemand) * 100);
  const remoteDemandPercentWidth = Math.ceil((bar.result_remote_demand / maxDemand) * 100);
  const salaryPercentWidth = Math.ceil((bar.avg_salary / maxSalary) * 100);

  const handleClick = useCallback(() => {
    if (rowId) {
      const value = bar[rowId] || bar.res_id;
      onSelect(value);
    } else {
      onSelect(bar.res_id);
    }
  }, [bar, onSelect, rowId]);

  const avgSalaryRoubles = new Intl.NumberFormat("ru-RU", {
    style: "currency",
    currency: "RUB",
    maximumFractionDigits: 0,
  }).format(bar.avg_salary);

  const formatNumber = (num: number) => new Intl.NumberFormat("ru-RU").format(num);

  return (
    <RatingItemWrapper onClick={handleClick}>
      <RatingItemName>{bar.res_name}</RatingItemName>
      <RatingItemChart>
        <RatingItemChartWrapper>
          <RatingItemChartBar width={demandPercentWidth} color="#26a0fc">
            {formatNumber(bar.result_demand)}
          </RatingItemChartBar>
          <RatingItemChartBar width={remoteDemandPercentWidth} color="#1585da">
            {formatNumber(bar.result_remote_demand)}
          </RatingItemChartBar>
        </RatingItemChartWrapper>
        <RatingItemChartBar width={salaryPercentWidth} color="#26e7a6">
          {avgSalaryRoubles}
        </RatingItemChartBar>
      </RatingItemChart>
    </RatingItemWrapper>
  );
};

export default React.memo(RatingItem);
