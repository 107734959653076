import React, { useState } from "react";
import Modal from "antd/lib/modal/Modal";
import Button from "antd/lib/button";

const AboutText = React.lazy(() => import("./AboutText"));

const MapAbout = () => {
  const [isVisible, setIsVisible] = useState(false);

  return (
    <div className="map-about">
      <div className="map-about__buttons">
        <Button className="vacancies__button" type="default" onClick={() => setIsVisible(true)}>
          Зачем нужен сервис
        </Button>
      </div>
      <Modal visible={isVisible} onCancel={() => setIsVisible(false)} footer={null} width={600}>
        <AboutText />
      </Modal>
    </div>
  );
};

export default React.memo(MapAbout);
