import axios from "axios";
import { IVacancy } from "../store/vacancies/vacancies.reducer";
import { IOptions } from "../types/NetworkState";
import { filterKey } from "../util/filterKeys";

const fetch = async (options: IOptions) => {
  try {
    const response = await axios.post<{ count: number; data: IVacancy[] }>(`/vacancies`, {
      region_id: options.region_id,
      [filterKey.skill]: options.filters[filterKey.skill],
      [filterKey.field]: options.filters[filterKey.field],
      [filterKey.position]: options.filters[filterKey.position],
    });
    return response.data.data;
  } catch (error) {
    throw new Error("Ошибка при загрузки вакансий");
  }
};

export const VacanciesAPI = { fetch };
