import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "..";

export const selectSkills = (state: RootState) => state.skills;

export const selectSkillsEntities = createSelector(selectSkills, (state) => state.entities);

export const selectSkillsOptions = createSelector(selectSkills, (state) =>
  state.entities
    .map((item) => ({ value: item.id, label: item.s_name }))
    .sort((a, b) => a.label.localeCompare(b.label, "ru"))
);

export const selectSkillById = (id?: number) =>
  createSelector(selectSkills, (state) => state.entities.find((item) => item.id === id));
