import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "..";

export const selectPositions = (state: RootState) => state.positions;

export const selectPositionsEntities = createSelector(selectPositions, (state) => state.entities);

export const selectPositionsOptions = createSelector(selectPositions, (state) =>
  state.entities
    .map((item) => ({ value: item.id, label: item.s_name }))
    .sort((a, b) => a.label.localeCompare(b.label, "ru"))
);

export const selectPositionById = (id?: number) =>
  createSelector(selectPositions, (state) => state.entities.find((item) => item.id === id));
