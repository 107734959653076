import React, { useMemo } from "react";
import {
  ObjectManagerFeature,
  YMapsApi,
  Map as YMap,
  GeoObject,
  Placemark,
} from "react-yandex-maps";

import useSize from "ahooks/lib/useSize";

type MapDrawerProps = {
  zoom: number;
  center: number[];
  onLoad: (ymaps: YMapsApi) => void;
  features: ObjectManagerFeature[];
  polygons: ObjectManagerFeature[];
  onClick: (e: any) => void;
  onBoundsChange: (e: any) => void;
};

const MapDrawer: React.FC<MapDrawerProps> = ({
  zoom,
  center,
  onLoad,
  onClick,
  features,
  polygons,
  onBoundsChange,
}) => {
  const size = useSize(document.body);

  const responsiveWidth = useMemo(() => {
    if (size && size.width) {
      return size.width < 1280 ? size.width : size.width - 440;
    } else {
      return "100%";
    }
  }, [size]);

  return (
    <YMap
      state={{ zoom, center }}
      onLoad={onLoad}
      width={responsiveWidth}
      height="100%"
      modules={["borders", "ObjectManager"]}
      onBoundsChange={onBoundsChange}
      options={{ autoFitToViewport: "none" }}
    >
      {features
        .filter((feature) => +feature.properties?.iconCaption > 0)
        .map((feature) => (
          <Placemark
            key={feature.id}
            geometry={feature.geometry as any}
            properties={feature.properties}
            options={feature.options}
            onClick={() => onClick(feature.id)}
          />
        ))}
      {polygons.map((feature) => (
        <GeoObject
          key={feature.id}
          geometry={{ type: feature.geometry.type, coordinates: feature.geometry.coordinates }}
          options={feature.options}
          properties={feature.properties}
        />
      ))}
    </YMap>
  );
};
export default MapDrawer;
