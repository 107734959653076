import axios from "axios";
import { IPosition } from "../store/positions/positions.reducer";

const fetch = async () => {
  try {
    const response = await axios.get<IPosition[]>("ref/specials");
    return Array.isArray(response.data) ? response.data : [];
  } catch (error) {
    throw new Error("Ошибка при загрузке списка навыков");
  }
};

export const PositionAPI = { fetch };
