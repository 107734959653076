import axios from "axios";
import { IRegion } from "../store/regions/regions.reducer";

const fetch = async () => {
  try {
    const regions = await axios.get<IRegion[]>("/regionlist");
    return Array.isArray(regions.data) ? regions.data : [];
  } catch (error) {
    throw new Error("Ошибка при загрузке списка регионов");
  }
};

export const RegionAPI = { fetch };
