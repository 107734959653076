import React, { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { OutputSelector } from "@reduxjs/toolkit";
import { LabeledValue } from "antd/lib/select";
import { RootState } from "../../store";
import { FilterSelect } from "./Filter.styled";

type FilterItemProps = {
  filterKey: string;
  value: number | string;
  onChange: (key: string, value: number) => void;
  placeholder: string;
  selector: OutputSelector<RootState, LabeledValue[], any>;
  action: any;
};

const FilterItem: React.FC<FilterItemProps> = ({
  filterKey,
  value,
  onChange,
  placeholder,
  selector,
  action,
}) => {
  const dispatch = useDispatch();
  const options = useSelector(selector);
  const handleChange = useCallback(
    (value: any) => onChange(filterKey, value),
    [filterKey, onChange]
  );

  useEffect(() => {
    dispatch(action());
  }, [action, dispatch]);

  return (
    <FilterSelect
      showSearch
      value={value}
      placeholder={placeholder}
      allowClear
      onChange={handleChange}
      options={options}
      optionFilterProp="label"
    />
  );
};

export default React.memo(FilterItem, (a, b) => a.value === b.value);
